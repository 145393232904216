<template>
  <div class="sld_invoice_header">
    <div class="container">
      <h3>{{ L["发票抬头管理"] }}</h3>

      <div class="block2 flex-col" @click="openInvoice">
        <span class="word24">新增发票抬头</span>
      </div>
      <template v-if="invoiceList.data.length > 0">
        <div class="mod2 flex-col">
          <div class="outer2 flex-row">
            <span class="txt14">发票抬头</span>
            <span class="txt14 info12">类型</span>
            <span class="txt14 word25">社会统一信用代码</span>
            <span class="txt14 word26">发票类型</span>
            <span class="txt14 word27">操作</span>
          </div>
        </div>
        <div class="bd10 flex-col">
          <template v-for="item in invoiceList.data" :key="item">
            <div class="group9 flex-row">
              <span class="info13">{{ item.invoiceTitle }}</span>
              <span class="info13 word28">{{ item.titleTypeValue }}</span>
              <span class="info13 txt15">{{ item.socialCreditCode ? item.socialCreditCode : '--' }}</span>
              <span class="info13 info14">{{ item.invoiceTypeValue }}</span>
              <span class="info13 info15"></span>
              <span class="info13 txt16" @click="edit(item)">编辑</span>
              <span class="info13 word29" @click="drop(item.invoiceId)">删除</span>
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="block3 flex-col">
          <div class="outer2 flex-row">
            <span class="txt14">发票抬头</span>
            <span class="txt14 info12">类型</span>
            <span class="txt14 word25">社会统一信用代码</span>
            <span class="txt14 word26">发票类型</span>
            <span class="txt14 word27">操作</span>
          </div>
        </div>
        <div class="block4 flex-col">
          <div class="section2 flex-col">
            <div class="group35 flex-col"></div>
            <div class="group36">
              <span class="word29">暂无数据，请</span>
              <span class="info15" @click="openInvoice">添加发票抬头</span>
            </div>
          </div>
        </div>
      </template>
      <el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current"
        :page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
        :hide-on-single-page="false" class="flex_row_end_center" v-if="invoiceList.data.length > 0"></el-pagination>
      <InvoiceDailog ref="dailog" @refresh="refresh"></InvoiceDailog>
    </div>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import InvoiceDailog from "./invoiceDailog.vue";
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
  name: "FinaceInvoiceHeader",
  components: {
    InvoiceDailog,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const invoiceList = reactive({ data: [] });
    const pageData = reactive({
      current: 1,
      pageSize: 10,
      total: 0
    });

    const getList = () => { //获取抬头列表
      let param = {
        current: pageData.current,
        pageSize: pageData.pageSize
      }
      proxy
        .$get('v3/member/front/invoiceTitle/list', param)
        .then(res => {
          invoiceList.data = res.data.list
          pageData.total = res.data.pagination.total;
        })
    };

    //页数改变
    const handleCurrentChange = e => {
      pageData.current = Math.floor(e);
      getList();
    };

    const setAuto = (id) => {
      let param = {
        invoiceId: id,
        isDefault: true
      }
      proxy
        .$post('v3/member/front/invoiceTitle/changeDefaultInvoice', param)
        .then(res => {
          if (res.state == 200) {
            ElMessage.success(res.msg);
            getList();
          } else {
            ElMessage.error(res.msg);
          }
        })
    };

    const edit = (item) => {
      proxy.$refs.dailog.resetData()
      proxy.$refs.dailog.visibility = true;
      proxy.$refs.dailog.title = '编辑发票抬头';
      proxy.$refs.dailog.isEdit = true;
      proxy.$refs.dailog.titleType = item.titleType.toString();
      proxy.$refs.dailog.invoiceTitle = item.invoiceTitle;
      proxy.$refs.dailog.invoiceType = item.invoiceType.toString();
      proxy.$refs.dailog.isDefault = item.isDefault.toString();
      proxy.$refs.dailog.invoiceId = item.invoiceId;
      if (item.titleType == 2) {
        proxy.$refs.dailog.socialCreditCode = item.socialCreditCode;
        proxy.$refs.dailog.registerAddr = item.registerAddr;
        proxy.$refs.dailog.registerPhone = item.registerPhone;
        proxy.$refs.dailog.bankName = item.bankName;
        proxy.$refs.dailog.bankAccount = item.bankAccount;
      }
    };

    const drop = (id) => { //删除发票抬头
      ElMessageBox.confirm('确定删除该发票抬头?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let param = {
          invoiceIds: id
        }
        proxy
          .$post('v3/member/front/invoiceTitle/delete', param)
          .then(res => {
            if (res.state == 200) {
              ElMessage.success(res.msg);
              getList();
            } else {
              ElMessage.error(res.msg);
            }
          })
      }).catch(() => { })
    }

    const openInvoice = () => {
      proxy.$refs.dailog.resetData()
      proxy.$refs.dailog.title = '添加发票抬头';
      proxy.$refs.dailog.visibility = true;
      proxy.$refs.dailog.isEdit = false;
    };

    const refresh = () => {
      proxy.$refs.dailog.visibility = false;
      getList();
    };

    onMounted(() => {
      getList();
    })

    return {
      L,
      pageData,
      handleCurrentChange,
      invoiceList,
      getList,
      setAuto,
      edit,
      drop,
      openInvoice,
      refresh,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/finace/invoiceHeader.scss";
</style>